import React from 'react'
import './about.css'
import IMG from '../../assets/me-img.jpeg'
import { FaAward } from "react-icons/fa6";
import { FaUserLock } from "react-icons/fa";
import { LuFolderEdit } from "react-icons/lu";

const About = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>

      <div className="container about__container">

        <div className="about__me">
          <div className="about__me-image">
            <img src={IMG} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className='about__icon' />
              <h5>Experience</h5>
              <small>1 Year +</small>
            </article>

            <article className="about__card">
              <FaUserLock className='about__icon' />
              <h5>Clients</h5>
              <small>2+</small>
            </article>

            <article className="about__card">
              <LuFolderEdit className='about__icon' />
              <h5>Projects</h5>
              <small>15+</small>
            </article>
          </div>

        <p>
        I'm passionate about AI/ML and web development, combining my expertise in both fields to craft innovative solutions. With a solid foundation in AI/ML principles, I've ventured into web development, where I freelance on diverse projects. My work spans from contributing to AI advancements, including a published paper in IEEE, to creating intuitive web interfaces. I thrive on tackling complex challenges and aim to bring my diverse skill set to impactful projects.
        </p>
        <a href="#contact" className='btn btn-primary'>Connect</a>
        </div>
      </div>
    </section>
  )
}

export default About
import React from 'react'
import IMG1 from '../../assets/p1.jpg'
import IMG2 from '../../assets/p2.jpg'
import IMG3 from '../../assets/p3.jpg'
import IMG4 from '../../assets/p4.jpg'
import IMG5 from '../../assets/p5.jpg'
import IMG6 from '../../assets/p6.jpg'
import './portfolio.css'

const data = [
  {
    id: 1,
    img: IMG1,
    title: 'GitHub Code Complexity Analyzer',
    github: 'https://github.com/suhasml/GitHub-Complexity-Analyser',
    live: 'https://github-analyser.onrender.com'
  },
  {
    id: 2,
    img: IMG2,
    title: 'Lip Reader AI - Generate Caption using lip movement',
    github: 'https://github.com/suhasml/Lip-Reader',
    live: 'https:github.comhttps://github.com/suhasml/Lip-Reader'
  },
  {
    id: 3,
    img: IMG3,
    title: 'Blind Navigation System - Android App',
    github: 'https://github.com/Rishi0812/RAYS',
    live: 'https://github.com/Rishi0812/RAYS'
  },
  {
    id: 4,
    img: IMG4,  
    title: 'Intruison Detection System - AI enabled',
    github: 'https://github.com/Rishi0812/UBA_for_Network_Security',
    live: 'https://github.com/Rishi0812/UBA_for_Network_Security'
  },
  {
    id: 5,
    img: IMG5,
    title: 'Chatbot - Skype Online Class Assistant',
    github: 'https://github.com/suhasml/Chatbot-Skype-dataset',
    live: 'https://github.com/suhasml/Chatbot-Skype-dataset'
  },
  {
    id: 6,
    img: IMG6,
    title: 'Automatic Farm Management System',
    github: 'https://github.com/suhasml/Farm-Management-System',
    live: 'https://github.com/suhasml/Farm-Management-System'
  }
]


const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Projects</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, img, title, github, live}) => {
            return (
              <article key={id} className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={img} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className='portfolio__item-cta'>
                  <a href={github} target="_blank" rel="noopener noreferrer" className='btn'>Github</a>
                  <a href={live} target="_blank" rel="noopener noreferrer" className='btn btn-primary'>Live</a> 
                </div>
              </article>               
            )
            })
        }
      </div>
    </section>
  )
}

export default Portfolio
import React from 'react'
import './footer.css'
import { FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
   <footer>
    <a href="#" className='footer__logo'>SUHAS</a>
    <ul className='permalinks'>
      <li><a href="#">Home</a></li>
      <li><a href="#about">About</a></li>
      <li><a href="#services">Services</a></li>
      <li><a href="#portfolio">Portfolio</a></li>
      <li><a href="#testimonials">Testimonials</a></li>
      <li><a href="#contact">Contact</a></li>
    </ul>

    <div className="footer__socials">
      <a href="https://www.instagram.com/suhas_ml/" target="_blank"><FaInstagram /></a>
      <a href="https://twitter.com/zuess05" target="_blank"><FaSquareXTwitter /></a>
      <a href="https://www.linkedin.com/in/suhasml" target="_blank"><FaLinkedin /></a>
    </div>

    <div className="footer__copyright">
      <small>&copy; Suhas M L 2023</small>
    </div>
   </footer>
  )
}

export default Footer
import React from 'react'
import { IoCheckmark } from "react-icons/io5";
import './services.css'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>AI Solutions</h3>
          </div>
          <ul className="service__list">
          <li><IoCheckmark className='service__list-icon' /> 
            <p>ML Models and Solutions</p>
          </li>
          <li><IoCheckmark className='service__list-icon' /> 
            <p>NLP Tools and Services</p>
          </li>
          <li><IoCheckmark className='service__list-icon' /> 
            <p>Coputer Vision Applications</p>
          </li>
          <li><IoCheckmark className='service__list-icon' /> 
            <p>Deployment and Integration</p>
          </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
          <li><IoCheckmark className='service__list-icon' /> 
            <p>Responsive Websites</p>
          </li>
          <li><IoCheckmark className='service__list-icon' /> 
            <p>Web Applications</p>
          </li>
          <li><IoCheckmark className='service__list-icon' /> 
            <p>Mobile Applications</p>
          </li>
          <li><IoCheckmark className='service__list-icon' /> 
            <p>Database Design and Management</p>
          </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Chatbots</h3>
          </div>
          <ul className="service__list">
          <li><IoCheckmark className='service__list-icon' /> 
            <p>Chatbot Development</p>
          </li>
          <li><IoCheckmark className='service__list-icon' /> 
            <p>AI-Powered Conversational Interfaces</p>
          </li>
          {/* <li><IoCheckmark className='service__list-icon' /> 
            <p>Graphic Design</p>
          </li> */}
          <li><IoCheckmark className='service__list-icon' /> 
            <p>Integration and Deployment</p>
          </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services
import React, { useRef } from 'react'
import './contact.css'
import { MdOutlineMailOutline } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_oe08aen', 'template_z2b5fqk', form.current, 'H45fm4az2Xd5HOI5i')

    e.target.reset()
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineMailOutline className='contact__option-icon' />
            <h4>Email</h4>
            <h5>suhasml37@gmail.com</h5>
            <a href="mailto:suhasml37@gmail.com" target="_blank" rel="noreferrer">Send a Mail</a>
          </article>
          <article className="contact__option">
            <FaWhatsapp className='contact__option-icon' />
            <h4>WhatsApp</h4>
            <h5>+91 93800 97084</h5>
            <a href="https://api.whatsapp.com/send?phone=+919380097084" target="_blank" rel="noreferrer">Drop a Message</a>
          </article>
          <article className="contact__option">
            <FaLinkedinIn className='contact__option-icon' />
            <h4>LinkedIn</h4>
            <h5>suhasml</h5>
            <a href="https://www.linkedin.com/in/suhasml" target="_blank" rel="noreferrer">Connect with me</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" id="name" placeholder="Your Full Name" required />
          <input type="email" name="email" id="email" placeholder="Your Email" required />
          <textarea name="message" id="message" placeholder="Your Message" required rows="7"></textarea>
          <button type="submit" className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact
import React from 'react'
import './testimonials.css'
import AVATAR1 from '../../assets/client1.jpg'
import AVATAR2 from '../../assets/client2.jpg'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const data = [
  {
    name: 'Karthi Sekhar, MultiTap',
    avatar: AVATAR1,
    review: 'Excellent work, delivered exactly what I needed. I will definitely be working with him again in the future.'
  },
  {
    name: 'Abhishek Kumar, NotBot',
    avatar: AVATAR2,
    review: 'Amazing work, very professional and delivered on time.'
  }
]

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Hear What Others Have to Say</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
        >
        {
          data.map(({ name, avatar, review }, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
                <div className="client__avatar">
                  <img src={avatar} alt={name} />
                </div>
                <h5 className='client__name'>{name}</h5>
                <small className='client__review'>{review}</small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials
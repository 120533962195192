import React from 'react'
import { IoMdHome } from "react-icons/io";
import { FaUserNinja } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { GrBusinessService } from "react-icons/gr";
import { BiMessageRoundedDetail } from "react-icons/bi";
import './nav.css'
import { useState } from 'react';

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <nav>
      <a href="#" onClick={() => setActiveNav('#')} className={activeNav==='#' ? 'active' : ''}><IoMdHome /></a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav==='#about' ? 'active' : ''}><FaUserNinja /></a>
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav==='#experience' ? 'active' : ''}><MdWork /></a>
      <a href="#services" onClick={() => setActiveNav('#services')} className={activeNav==='#services' ? 'active' : ''}><GrBusinessService /></a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav==='#contact' ? 'active' : ''}><BiMessageRoundedDetail /></a>
    </nav>
  )
}

export default Nav
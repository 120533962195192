import React from 'react'
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import './experience.css'

const Experience = () => {
  return (
    <section id = "experience">
        <h5>The skills I Have</h5>
        <h2>Experience</h2>

        <div className='container experience__container'>
          <div className="experience_frontend">
            <h3>AI/ML</h3>
            <div className="experience__content">
              <article className="experience__details">
                <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
                <div>
                <h4>Python</h4>
                <small className='text-light'>Proficient</small>
                </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
                <div>
                <h4>TensorFlow, Keras, PyTorch</h4>
                <small className='text-light'>Proficient</small>
                </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
                <div>
                <h4>NLP</h4>
                <small className='text-light'>Proficient</small>
                </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
              <div>
              <h4>Computer Vision</h4>
                <small className='text-light'>Proficient</small>
              </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
                <div>
                <h4>Generative AI</h4>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
                <div>
                <h4>Chatbots</h4>
                <small className='text-light'>Advacned</small>
                </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
               <div>
               <h4>AWS/GCP</h4>
                <small className='text-light'>Intermediate</small>
               </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
               <div>
               <h4>Git/GitHub</h4>
                <small className='text-light'>Intermediate</small>
               </div>
              </article>
            </div>
          </div>
          <div className="experience_backend">
            <h3>Web Development</h3>
            <div className="experience__content">
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
                <div>
                <h4>HTML/CSS</h4>
                <small className='text-light'>Advanced</small>
                </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
                <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
                <div>
                <h4>ReactJS</h4>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
                <div>
                <h4>MongoDB, SQL </h4>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
                <div>
                <h4>Node.js</h4>
                <small className='text-light'>Intermediate</small>
                </div>
              </article>
              <article className="experience__details">
              <IoCheckmarkDoneCircleSharp className='experience__details-icon'/>
                <div>
                <h4>RESTful APIs</h4>
                <small className='text-light'>Beginner</small>
                </div>
              </article>
            </div>

          </div>
        </div>
    </section>
  )
}

export default Experience